import React, { useEffect, useState } from "react";
import axios from "axios";
import { useNavigate } from 'react-router-dom';
// import { product_data } from "../data/product_data";
import { NavLink } from "react-router-dom";
import ProductDetailListFR from "../components/ProductDetailFR";
import LogoutFR from "../components/LogoutFR";
 
const HomeFR = () => {
  const navigate = useNavigate();
 
  // const [Product, setProduct] = useState([]);
  const [Commande, setCommande] = useState([]);
  const [StatutCommande, setStatutCommande] = useState([]);
 
  // useEffect(() => {
  //   setProduct(product_data);
  // }, [Product]);
 
 
 
  
  useEffect(() => {
    const getCommande = async () => {
      try {
        const token = sessionStorage.getItem('authToken');
 
        if (!token) {
          sessionStorage.removeItem("authToken");
          // navigate("/fr/connexion"); 
          setTimeout(() => {
            navigate("/fr/connexion"); // Redirection vers la page de connexion
          }, 500);
          return;
        }
 
        // const orderid = sessionStorage.getItem("orderID");
        // if (!orderid) {
        //   console.error('Order ID is missing.');
        //   return;
        // }
 
        
        // const response = await axios.get('http://localhost:3001/api/commande', {
        const response = await axios.get('/api/commande', {
          headers: { 'Authorization': `Bearer ${token}` }
        });

        if (response.data) {
          setCommande(response.data); 
        }
      } catch (error) {
        if (error.response && error.response.status === 400) {
          // Token expiré, rediriger vers la page de connexion ou tenter de rafraîchir le token
          console.error('Token expired, redirecting to login');
          sessionStorage.removeItem("authToken");
          navigate('/fr/connexion');
        } else {
          console.error('Failed to fetch command:', error);
        }
      }
    };
    getCommande();
  }, [navigate]); 

  useEffect(() => {
    setStatutCommande(Commande["Statut de la commande"]);
  }, [StatutCommande]);

  
  

  return (
    <>
    {/* <pre>{JSON.stringify(Commande, null, 2)}</pre> */}
    <header>
      <ul>
          {/* <li>
            <NavLink
              exact
              to="/en"
              title="EN"
              // activeClassName="header-activ"
              // onClick={() => {
              //   setShowLinks(false);
              // }}
            >
              <h3>EN</h3>
            </NavLink>
          </li> */}
          <li>
          <LogoutFR/>
          </li>
        </ul>
    </header>
    <main >
      <section className="order margin-b-32">
        <div class="order-status">
        <div className="clientTopLeft">
            <div className="OrderNumber">
            Commande #<strong>{Commande["No Contrat"]}</strong>
            </div>
            <div className="clientTopDate">
              Commande passée le : {Commande["DateVente"]}
            </div>
          </div>
          <div>
              <ul>
                  <li className="order_placed">Commande confirmée</li>
                  <li className={(Commande["Statut Commande"] === "En production" || Commande["Statut Commande"] === "En préparation à l'entrepot") ? "statut-commande" : ""}
                  >Commande en préparation</li>
                  {/* <li className={Commande["Statut Commande"] === "Pn preparation a l'entrepot" ? "statut-commande" : ""}>En préparation à l’entrepôt</li> */}
                  <li className={Commande["Statut Commande"] === "En attente de livraison" ? "statut-commande" : ""}>Commande prète</li>
                  <li className={Commande["Statut Commande"] === "Livré" ? "statut-commande" : ""}>Commande livré</li>
              </ul>
          </div>
        </div>
        {Commande["Statut Commande"] === "En attente de livraison" ? (
          <>
            <div className="scheduleOrder desktop">
              <p className="">Votre commande est prête pour expédition.<br/> Veuillez contacter notre équipe au <a href="tel:+18889880429">+1 (888) 988-0429 </a> pour planifier la livraison.</p>
              <p className="anotation">* Si vous avez déjà été contacté, nous vous remercions de patienter en attendant votre livraison.</p>
            </div>
            <div className="scheduleOrder mobile">
            <p className="">Votre commande est prête. <br/> Contacter notre équipe au <br/><a href="tel:+18889880429"> +1 (888) 988-0429</a>  <br/>pour planifier la livraison.</p>
            <p className="anotation">* Si vous avez déjà été contacté, nous vous remercions de patienter en attendant votre livraison.</p>
          </div>
        </>
        ):""}
        <h2 className="orderDetailsTitle">
          Détails de la commande
        </h2>
        <div className="orderHeader desktop">
          <p className="productSku">sku</p>
          <p className="product">Article</p>
          <p className="productEta">Date de livraison</p>
          <p className="productQty">Quantité</p>
          <p className="productPrice">Prix ​​unitaire</p>
        </div>
        <div className="orderHeader mobile">
          <p className="product">Article</p>
          <p className="productEta">Date de livraison</p>
        </div>
      <ProductDetailListFR/>
      </section>
      <section className="recapOrder">
        <div className="subttotal">
          <p>Sous-total: &nbsp;</p>
          <p>${Commande["sum_Total Sans Taxe"]}</p>
        </div>
        <div className="shipping">
          <p>Livraison: &nbsp;</p>
          <p>${Commande["sum_Prix Livraison"]}</p>
        </div>
        <div className="tax">
          <p>Taxe: &nbsp;</p>
          <p>${Commande["sum_Total Taxes"]}</p>
        </div>
        <div className="total">
          <p>TOTAL: &nbsp;</p>
          <p>${Commande["sum_Totale avec Taxe"]}</p>
        </div>
      </section>
    </main>
    </>
  );
};
 
export default HomeFR;