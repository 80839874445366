import React, { useEffect, useState } from "react";
import axios from "axios";
import { useNavigate } from 'react-router-dom';
// import { product_data } from "../data/product_data";
import { NavLink } from "react-router-dom";
import ProductDetailList from "../components/ProductDetail";
import Logout from "../components/Logout";
 
const Home = () => {
  const navigate = useNavigate();
 
  // const [Product, setProduct] = useState([]);
  const [Commande, setCommande] = useState([]);
  const [StatutCommande, setStatutCommande] = useState([]);
 
  // useEffect(() => {
  //   setProduct(product_data);
  // }, [Product]);
 
 
 
  
  useEffect(() => {
    const getCommande = async () => {
      try {
        const token = sessionStorage.getItem('authToken');
 
        if (!token) {
          sessionStorage.removeItem("authToken");
          navigate("/en/login"); 
          return;
        }

        // const response = await axios.get('http://localhost:3001/api/commande', {
        const response = await axios.get('/api/commande', {
          headers: { 'Authorization': `Bearer ${token}` }
        });
 
        if (response.data) {
          setCommande(response.data); 
        }
      } catch (error) {
        if (error.response && error.response.status === 400) {
          // Token expiré, rediriger vers la page de connexion ou tenter de rafraîchir le token
          console.error('Token expired, redirecting to login');
          sessionStorage.removeItem("authToken");
          navigate('/en/login');
        } else {
          console.error('Failed to fetch command:', error);
        }
      }
    };
    getCommande();
  }, [navigate]); 

  useEffect(() => {
    setStatutCommande(Commande["Statut de la commande"]);
  }, [StatutCommande]);

  
  

  return (
    <>
    {/* <pre>{JSON.stringify(Commande, null, 2)}</pre> */}
    <header>
        <ul>
          {/* <li>
            <NavLink
              exact
              to="/fr"
              title="FR"
              // activeClassName="header-activ"
              // onClick={() => {
              //   setShowLinks(false);
              // }}
            >
              <h3>FR</h3>
            </NavLink>
          </li> */}
          <li>
          <Logout/>
          </li>
        </ul>
    </header>
    <main >
      <section className="order margin-b-32">
        <div class="order-status">
          <div className="clientTopLeft">
            <div className="OrderNumber">
            Order #<strong>{Commande["No Contrat"]}</strong>
            </div>
            <div className="clientTopDate">
              Order Placed on: {Commande["DateVente"]}
            </div>
          </div>
          <div>
              <ul>
                 <li className="order_placed">Order confirmed</li>
                  <li className={(Commande["Statut Commande"] === "En production" || Commande["Statut Commande"] === "En préparation à l'entrepot") ? "statut-commande" : ""}
                  >Order in progress</li>
                  {/* <li className={Commande["Statut Commande"] === "Pn preparation a l'entrepot" ? "statut-commande" : ""}>In preparation at warehouse</li> */}
                  <li className={Commande["Statut Commande"] === "En attente de livraison" ? "statut-commande" : ""}>Order ready</li>
                  <li className={Commande["Statut Commande"] === "Livré" ? "statut-commande" : ""}> Order Delivered</li>
              </ul>
          </div>
        </div>
        {Commande["Statut Commande"] === "En attente de livraison" ? (
          <>
            <div className="scheduleOrder desktop">
              <p className="">Your order is ready for shipment. Please contact our delivery service at <a href="tel:+18889880429"> +1 (888) 988-0429</a>  to schedule the receipt.</p>
              <p className="anotation">* If you have already been contacted, we thank you for your patience while waiting for your delivery.</p>
            </div>
            <div className="scheduleOrder mobile">
            <p className="">Your order is ready for shipment. <br/> Please contact our delivery service at <br/><a href="tel:+18889880429"> +1 (888) 988-0429</a>  <br/>to schedule the receipt.</p>
            <p className="anotation">* If you have already been contacted, we thank you for your patience while waiting for your delivery.</p>
          </div>
        </>
        ):""}
        
        <h2 className="orderDetailsTitle">
          Order Details
        </h2>
        <div className="orderHeader desktop">
          <p className="productSku">sku</p>
          <p className="product">Product</p>
          <p className="productEta">Delivery Date</p>
          <p className="productQty">Quantity</p>
          <p className="productPrice">Unit Price</p>
        </div>
        <div className="orderHeader mobile">
          <p className="product">Product</p>
          <p className="productEta">Delivery Date</p>
        </div>
      <ProductDetailList Commande={Commande} />
      </section>
      <section className="recapOrder">
        <div className="subttotal">
          <p>Subtotal: &nbsp;</p>
          <p>${Commande["sum_Total Sans Taxe"]}</p>
        </div>
        <div className="shipping">
          <p>Shipping: &nbsp;</p>
          <p>${Commande["sum_Prix Livraison"]}</p>
        </div>
        <div className="tax">
          <p>Tax: &nbsp;</p>
          <p>${Commande["sum_Total Taxes"]}</p>
        </div>
        <div className="total">
          <p>TOTAL: &nbsp;</p>
          <p>${Commande["sum_Totale avec Taxe"]}</p>
        </div>
      </section>
    </main>
    </>
  );
};
 
export default Home;