import React, { useEffect, useState } from "react";
import axios from "axios";
import { useNavigate } from 'react-router-dom';




const ProductDetailFR = () => {
  const navigate = useNavigate();


  const [ProductDetailList, setProductDetailList] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const getCommande = async () => {
      try {
        const token = sessionStorage.getItem('authToken');
 
        if (!token) {
          sessionStorage.removeItem("authToken");
          // navigate("/fr/connexion"); 
          setTimeout(() => {
            navigate("/fr/connexion"); // Redirection vers la page de connexion
          }, 500);
          return;
        }


 
        // const orderid = sessionStorage.getItem("orderID");
        // if (!orderid) {
        //   console.error('Order ID is missing.');
        //   return;
        // }
 
        
        // const response = await axios.get('http://localhost:3001/api/product', {
        const response = await axios.get('/api/product', {
          headers: { 'Authorization': `Bearer ${token}` }
        });
 
        if (response.data) {
          setProductDetailList(response.data); 
        }
      } catch (error) {
        console.error('Failed to fetch command:', error);
      }finally {
        setLoading(false); // Terminer le chargement après avoir reçu les données
      }
    };
    getCommande();
  },[]); 

  if (loading) {
    return <p>Chargement des données...</p>;
  }

  // Converti la date présente
  const currentDate = new Date();
  const formattedDate = currentDate.getFullYear() + '-' 
                      + String(currentDate.getMonth() + 1).padStart(2, '0') + '-' 
                      + String(currentDate.getDate()).padStart(2, '0');


  return (
    <>
    <ul className="orderProductWall desktop">
      {/* <pre>{JSON.stringify(ProductDetailList, null, 2)}</pre> */}
      {ProductDetailList.length > 1 ? (
        ProductDetailList.map((product) => { 
          if (product["CodeCategorie"] === "9LI") {
            return null; // Ne rien retourner si la condition est remplie
          }
          return (
             <li
            className="product-container"
            key={product.SKU}
            > 
              <div className="productSku">
                <p>{product.SKU}</p> 
              </div>
              {/* <a href={product["URL_fr"]} target="blank" className="product"></a> */}
              <div className="product">
                <figure>
                  <img
                    src={product["BASE_IMAGE"]}
                    alt={"Product" + `${product["Name_en"]}`}
                  />
                </figure>
                <div className="productInfo">
                  <h3>
                  {product["Name_fr"] === "" || product["Name_fr"] === null
                    ? product["Nom_Meublex"]
                    : product["Name_fr"]}
                  </h3>
                  <p>
                    {product["FINI_DESC1"] === "" || product["FINI_DESC1"] === null
                    ? product["FINI_CODE"]
                    : product["FINI_DESC1"]}
                  </p>  
                    
                </div>
              </div>
              <div className="productEta">
              {product["Date_Requise_Fr"] < formattedDate && product["Check delivery"] === "delivered" ? (
                  <p style={{color:"#63b263"}}>Livré</p> 
                ) : <p>{product["Date_Requise_Fr"]}</p> }
              </div>
              <div className="productQty">
                <p>{product["sum_QteVendue"]}</p> 
              </div>
              <div className="productPrice">
                <p> {`${product["PrixUnitaire"]}` + "$" }</p> 
              </div>
            </li>
                  );
                })
        ) : 
        <li
        className="product-container"
        key={ProductDetailList[0].SKU}
        > 
          <div className="productSku">
            <p>{ProductDetailList[0].SKU}</p> 
          </div>
          {/* <a href={ProductDetailList[0]["URL_fr"]} target="blank" className="product"></a> */}
          <div className="product">
            <figure>
              <img
                src={ProductDetailList[0]["BASE_IMAGE"]}
                alt={"Product" + `${ProductDetailList[0]["Name_en"]}`}
              />
            </figure>
            <div className="productInfo">
            <h3>
            {ProductDetailList[0]["Name_fr"] === "" || ProductDetailList[0]["Name_fr"] === null
              ? ProductDetailList[0]["Nom_Meublex"]
              : ProductDetailList[0]["Name_fr"]}
            </h3>
              <p>
                {/* {ProductDetailList[0]["FINI_DESC1"]} */}
                {ProductDetailList[0]["FINI_DESC1"] === "" || ProductDetailList[0]["FINI_DESC1"] === null
                  ? ProductDetailList[0]["FINI_CODE"]
                  : ProductDetailList[0]["FINI_DESC1"]}
                </p>    
            </div>
          </div>
          <div className="productEta">
          {ProductDetailList[0]["Date_Requise_Fr"] < formattedDate && ProductDetailList[0]["Check delivery"] === "delivered" ? ( <p style={{color:"#63b263"}}>Livré</p> ) 
            : ProductDetailList[0]["Date_Requise_Fr"] < formattedDate && ProductDetailList[0]["Statut Commande"] === "En attente de livraison" ? ( <p style={{color:"#c01f1f"}}>En attente de planification</p> ) 
            : <p>{ProductDetailList[0]["Date_Requise_Fr"]}</p>
          }
          </div>
          <div className="productQty">
            <p>{ProductDetailList[0]["sum_QteVendue"]}</p> 
          </div>
          <div className="productPrice">
            <p> {`${ProductDetailList[0]["PrixUnitaire"]}` + "$" }</p> 
          </div>
        </li>}
  </ul>



  {/* VERSION MOBILE */}
  <ul className="orderProductWall mobile">
  {/* <pre>{JSON.stringify(ProductDetailList, null, 2)}</pre> */}
  {ProductDetailList.length > 1 ? (
    ProductDetailList.map((product) => { 
      if (product["CodeCategorie"] === "9LI") {
        return null; // Ne rien retourner si la condition est remplie
      }
      return (
        <li
        className="product-container"
        key={product.SKU}
        > 
          {/* <a href={product["URL_fr"]} target="blank" className="product"></a> */}
          <div className="product">
            <figure>
              <img
                src={product["BASE_IMAGE"]}
                alt={"Product" + `${product["Name_en"]}`}
              />
            </figure>
            <div className="productInfo">
              <h3>
              {product["Name_fr"] === "" || product["Name_fr"] === null
                ? product["Nom_Meublex"]
                : product["Name_fr"]}
              </h3>
              <p>
              {product["FINI_DESC1"] === "" || product["FINI_DESC1"] === null
                    ? product["FINI_CODE"]
                    : product["FINI_DESC1"]}  
              </p> 
              <p>{"SKU: " + `${product.SKU}`}</p>  
              <div className="qtyPrice margin-b-2">
                <p className="qty">{`${product["sum_QteVendue"]}` + "x "}</p> 
                <p> {"$" + `${product["PrixUnitaire"]}`}</p> 
              </div>    
            </div>
          </div>
          <div className="productEta">
          {product["Date_Requise_Fr"] < formattedDate && product["Check delivery"] === "delivered" ? (
            <p style={{color:"#63b263"}}>Livré</p> 
            ) : <p>{product["Date_Requise_Fr"]}</p> }
          </div>
        </li>
              );
            })
    ) : 
    <li
    className="product-container"
    key={ProductDetailList[0].SKU}
    > 
      {/* <a href={ProductDetailList[0]["URL_fr"]} target="blank" className="product"></a> */}
      <div className="product">
        <figure>
          <img
            src={ProductDetailList[0]["BASE_IMAGE"]}
            alt={"Product" + `${ProductDetailList[0]["Name_en"]}`}
          />
        </figure>
        <div className="productInfo">
        <h3>
        {ProductDetailList[0]["Name_fr"] === null || ProductDetailList[0]["Name_fr"] === ""
          ? ProductDetailList[0]["Nom_Meublex"]
          : ProductDetailList[0]["Name_fr"]}
        </h3>
          <p>
          {ProductDetailList[0]["FINI_DESC1"] === "" || ProductDetailList[0]["FINI_DESC1"] === null
            ? ProductDetailList[0]["FINI_CODE"]
            : ProductDetailList[0]["FINI_DESC1"]}
          </p>  
          <p>{"SKU: " + `${ProductDetailList[0]["SKU"]}`}</p>
          <div className="qtyPrice margin-b-2">
            <p className="qty">{`${ProductDetailList[0]["sum_QteVendue"]}` + "x "}</p> 
            <p> {"$" + `${ProductDetailList[0]["PrixUnitaire"]}`}</p> 
          </div>  
        </div>
      </div>
      <div className="productEta">
      {ProductDetailList[0]["Date_Requise_Fr"] < formattedDate && ProductDetailList[0]["Check delivery"] === "delivered" ? (
        <p style={{color:"#63b263"}}>Livré</p> 
        ) : <p>{ProductDetailList[0]["Date_Requise_Fr"]}</p> }
      </div>
      {/* <div className="productQty">
        <p>{ProductDetailList[0]["sum_QteVendue"]}</p> 
      </div>
      <div className="productPrice">
        <p> {`${ProductDetailList[0]["PrixUnitaire"]}` + "$" }</p> 
      </div> */}
    </li>}
  </ul>
</>   
  );
};

export default ProductDetailFR;